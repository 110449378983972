import Vue from 'vue';
import {mapActions, mapGetters, mapMutations} from "vuex";
import {email, maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";
import {camelCase} from "lodash";

export default {
  name: 'thanks',
  data() {
    return {
      payload: {
        name: '',
        email: '',
        phone: '',
        event_id: '',
        promocode: '',
        consent: false,
        sign_lang: 0
      },
      validationErrors: {}
    }
  },
  validations: {
    payload: {
      name: {
        required,
        maxLength: maxLength(60),
      },
      email: {
        required,
        maxLength: maxLength(60),
        email,
      },
      phone: {
        minLength: minLength(9)
      },
      consent: {
        sameAs: sameAs(() => true)
      }
    }
  },
  components:{
  },
  computed: {
    ...mapGetters({
      popup: 'system/popup',
      userData: 'marathon/userInfo',
      sendDataSubscribeLoading: 'marathon/sendSubscribeLoading',
      showSubscribePopupData: 'marathon/showSubscribePopup',
    }),
    ...mapGetters([
      'languages',
      'currentLanguage',
    ]),
    nameErrors() {
      let error = [];
      if (!this.$v.payload.name.$dirty) {
        return error;
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('fieldRequired').replace(':name', this.$t('name')))
      }

      if (!this.$v.payload.name.maxLength) {
        error.push(this.$t('maxLengthField').replace(':field', this.$t('name')).replace(':count', 60));
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row);
        })
      }

      return error;
    },
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('fieldRequired').replace(':name', this.$t('email')))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('maxLengthField').replace(':field', this.$t('email')).replace(':count', 60));
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        })
      }

      return error;
    },
    phoneError() {
      let error = [];
      if (!this.$v.payload.phone.$dirty) {
        return error;
      }
      if (!this.$v.payload.phone.minLength) {
        error.push(this.$t('validationLengthPhone'));
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    acceptAgreementError() {
      let error = [];
      if (!this.$v.payload.consent.$dirty) {
        return error;
      }
      if (!this.$v.payload.consent.sameAs) {
        error.push(this.$t('validateAcceptAgreement'));
      }
      return error;
    },
  },
  created(){
     this.setData();
     this.$nextTick(()=> {
       this.payload.event_id = this.showSubscribePopupData.eventId;
     });
  },
  methods: {
    ...mapActions({
      sendSubscribeData: `marathon/SEND_SUBSCRIBE_DATA`,
    }),
    ...mapMutations({
      changeContent: `system/POPUP_TEXT_CONTENT`,
      changeUserInfo: `marathon/CHANGE_USER_INFO`,
      changeShowSubscribePopup: `marathon/CHANGE_POPUP_INFO`,
      changeShowSubscribe: `marathon/CHANGE_SHOW_SUBSCRIBE_POPUP`,
      clearUserInfoData: `marathon/CLEAR_USER_INFO_DATA`,
      changeShowSubscribeThanks: `system/SHOW_POPUP_THANKS`,
    }),
    setData(){
      this.payload.name = this.userData.name;
      this.payload.email = this.userData.email;
      this.payload.phone = this.userData.phone;
      this.payload.country = this.userData.country;
      this.payload.city = this.userData.city;
    },
    submit(){
      this.$v.$touch();
      if (!this.$v.$invalid) {

        let obj = {};
        obj.name = this.payload.name;
        obj.email = this.payload.email;
        obj.promocode = this.payload.promocode;
        obj.event_id = this.payload.event_id;
        obj.lang = this.currentLanguage.key;
        if(typeof this.payload.phone === 'undefined'){

        }else{
          obj.phone = this.payload.phone;
        }

        if( parseInt(this.payload.lang) === 4){
          obj.sign_lang = 1;
        }else{
          obj.sign_lang = 0;
        }
        console.log(obj)
        this.sendSubscribeData(obj).then((response) => {
          this.$toasted.success(this.$t('successRegisterToEvent'));
          this.changeShowSubscribePopup(false);
          this.changeShowSubscribe(false);
          setTimeout(()=> {
            this.changeShowSubscribeThanks(true);
          },200)
          this.clearUserInfoData();
        }).catch(error => {
          let formattedMessage = null;

          if (error && error.response) {
            if (error.response.data.message) {
              formattedMessage = error.response.data.message;
            }

            if (error.response.data.error) {
              formattedMessage = error.response.data.error.message;
            }

            if (error.response.data.errors) {
              Object.keys(error.response.data.errors).forEach(errorKey => {
                error.response.data.errors[errorKey].forEach((line) => {
                  formattedMessage += `\r\n${line}`;
                });
              });
            }
          }

          formattedMessage && this.$toasted.error(formattedMessage);
        });
      }
    }
  },
  destroyed() {
    this.$nextTick(()=> {
      this.changeShowSubscribePopup(true);
      this.changeShowSubscribe({show:false,eventId: null});
    })
  }
}
